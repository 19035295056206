@import url("https://fonts.googleapis.com/css?family=Rubik:400,500,600,700|Montserrat:900,700");
html {
  overflow-x: hidden;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    color: #111;
  }
  
  h1, .h1 {
    font-size: 2.5rem;
  }
  
  h2, .h2 {
    font-size: 44px;
    line-height: 1.3;
  }
  
  h3, .h3 {
    font-size: 1.5rem;
  }
  
  h4, .h4 {
    font-size: 1.3rem;
    line-height: 30px;
  }
  
  h5, .h5 {
    font-size: 1.25rem;
  }
  
  h6, .h6 {
    font-size: 1rem;
  }
  
  p {
    line-height: 30px;
  }
  
  .navbar-toggle .icon-bar {
    background: #FF715B;
  }
  
  input[type=email],
  input[type=password],
  input[type=text],
  input[type=tel] {
    box-shadow: none;
    height: 45px;
    outline: none;
    font-size: 14px;
  }
  
  .form-control {
    box-shadow: none;
    border-radius: 0;
  }
  .form-control:focus {
    box-shadow: none;
    border: 1px solid #FF715B;
  }
  
  .py-7 {
    padding: 7rem 0px;
  }
  
  .btn {
    display: inline-block;
    font-size: 14px;
    font-size: 0.8125rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 0.7rem 2rem;
    text-transform: uppercase;
    border-radius: 5px;
    border: 2px solid transparent;
    transition: all 0.35s ease;
    cursor: pointer;
  }
  .btn.btn-icon i {
    font-size: 16px;
    vertical-align: middle;
    margin-right: 5px;
  }
  .btn:focus {
    outline: 0px;
    box-shadow: none;
  }
  
  .btn-main {
    background: #FF715B;
    color: #fff;
    border-color: #FF715B;
  }
  .btn-main:hover {
    color: #FF715B;
    background: transparent;
  }
  
  .btn-main-2 {
    background: #FF715B;
    color: #fff;
    border-color: #FF715B;
  }
  .btn-main-2:hover {
    color: #FF715B;
    background: transparent;
    border-color: #FF715B;
  }
  
  .btn-solid-border {
    border: 2px solid #FF715B;
    background: transparent;
    color: #111;
  }
  .btn-solid-border:hover {
    border: 2px solid #FF715B;
    color: #fff;
    background: #FF715B;
  }
  
  .btn-transparent {
    background: transparent;
    color: #111;
    border-color: #6F8BA4;
  }
  .btn-transparent:hover {
    background: #6F8BA4;
    color: #fff;
  }
  
  .btn-white {
    background: #fff;
    border-color: #fff;
    color: #111;
  }
  .btn-white:hover {
    background: #FF715B;
    color: #fff;
    border-color: #FF715B;
  }
  
  .btn-solid-white {
    border-color: #fff;
    color: #fff;
  }
  .btn-solid-white:hover {
    background: #fff;
    color: #111;
  }
  
  .btn-round {
    border-radius: 4px;
  }
  
  .btn-round-full {
    border-radius: 50px;
  }
  
  .btn.active:focus,
  .btn:active:focus,
  .btn:focus {
    outline: 0;
  }
  
  .bg-gray {
    background: #eff0f3;
  }
  
  .bg-primary {
    background: #FF715B;
  }
  
  .bg-primary-dark {
    background: #ff4528;
  }
  
  .bg-primary-darker {
    background: #f42100;
  }
  
  .bg-dark {
    background: #111;
  }
  
  .bg-gradient {
    background-image: linear-gradient(145deg, rgba(19, 177, 205, 0.95) 0%, rgba(152, 119, 234, 0.95) 100%);
    background-repeat: repeat-x;
  }
  
  .section {
    padding: 100px 0;
  }
  
  .section-sm {
    padding: 70px 0;
  }
  
  .section-title {
    margin-bottom: 70px;
  }
  .section-title .title {
    font-size: 50px;
    line-height: 50px;
  }
  .section-title p {
    color: #666;
    font-family: "Montserrat", sans-serif;
  }
  
  .subtitle {
    color: #FF715B;
    font-size: 14px;
    letter-spacing: 1px;
  }
  
  .overly {
    position: relative;
  }
  .overly:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #000;
  }
  
  .overly-2 {
    position: relative;
  }
  .overly-2:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }
  
  .text-sm {
    font-size: 14px;
  }
  
  .text-md {
    font-size: 2.25rem;
  }
  
  .text-lg {
    font-size: 3.75rem;
  }
  
  .no-spacing {
    letter-spacing: 0px;
  }
  
  /* Links */
  a {
    color: #111;
    text-decoration: none;
    display: inline-block;
    transition: 0.3s;
  }
  
  a:focus,
  a:hover {
    color: #FF715B;
    text-decoration: none;
  }
  
  a:focus {
    outline: none;
  }
  
  .content-title {
    font-size: 40px;
    line-height: 50px;
  }
  
  .page-title {
    padding: 170px 0px 20px 0px;
    position: relative;
  }
  .page-title .block h1 {
    color: #fff;
  }
  .page-title .block p {
    color: #fff;
  }
  .page-title .breadcumb-nav {
    margin-top: 80px;
    padding-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.06);
  }
  
  .slick-slide:focus,
  .slick-slide a {
    outline: none;
  }
  
  @media (max-width: 575px) {
    h2,
  .h2 {
      font-size: 1.8rem;
      line-height: 42px;
    }
  }
  @media screen and (min-width: 1216px) {
    .container:not(.is-max-desktop) {
      max-width: 1140px;
    }
  }
  @media (max-width: 1023px) {
    .container {
      max-width: 765px;
    }
  }
  @media (max-width: 768px) {
    .container {
      max-width: 540px;
    }
  }
  
  .container,
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .container-fluid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  
  .heading {
    display: block;
    font-size: unset;
    letter-spacing: unset;
    margin-bottom: 5px;
    text-transform: unset;
  }
  
  .column {
    padding: 15px;
  }
  
  .columns {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: -15px;
  }
  
  .columns:last-child {
    margin-bottom: -15px;
  }
  
  .list-inline {
    padding-left: 0;
    list-style: none;
  }
  .list-inline .list-inline-item {
    display: inline-block;
  }
  .list-inline .list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  
  .w-100 {
    width: 100%;
  }
  
  .ml-auto {
    margin-left: auto !important;
  }
  
  .text-white {
    color: #fff;
  }
  
  .text-white-50 {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .bg-white {
    background-color: #fff;
  }
  
  .rounded {
    border-radius: 4px;
  }
  
  .bg-light {
    background-color: #F8F9FA;
  }
  
  .input {
    border-radius: 0;
    box-shadow: unset;
    padding-left: 15px;
    padding-right: 15px;
  }
  .input:focus {
    box-shadow: none;
    border: 1px solid #FF715B !important;
  }
  
  textarea.input {
    padding-top: 15px;
    min-height: 150px;
  }
  
  .media img {
    max-width: initial;
  }
  
  .lead {
    font-size: 1.25rem;
  }
  
  #wrapper-work {
    overflow: hidden;
    padding-top: 100px;
  }
  #wrapper-work ul li {
    width: 50%;
    float: left;
    position: relative;
  }
  #wrapper-work ul li img {
    width: 100%;
    height: 100%;
  }
  #wrapper-work ul li .items-text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
    padding-left: 44px;
    padding-top: 140px;
  }
  #wrapper-work ul li .items-text h2 {
    padding-bottom: 28px;
    padding-top: 75px;
    position: relative;
  }
  #wrapper-work ul li .items-text h2:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 75px;
    height: 3px;
    background: #fff;
  }
  #wrapper-work ul li .items-text p {
    padding-top: 30px;
    font-size: 16px;
    line-height: 27px;
    font-weight: 300;
    padding-right: 80px;
  }
  
  /*--
      features-work Start 
  --*/
  #features-work {
    padding-top: 50px;
    padding-bottom: 75px;
  }
  #features-work .block ul li {
    width: 19%;
    text-align: center;
    display: inline-block;
    padding: 40px 0px;
  }
  
  .navigation .btn {
    box-shadow: none;
  }
  
  @media (max-width: 1023px) {
    .navbar-brand .navbar-item {
      padding-left: 0;
    }
  }
  .navbar-brand .navbar-item img {
    max-height: unset;
  }
  
  .navbar-burger {
    height: 3rem;
    width: 3rem;
    border: 0;
    margin-top: 5px;
    outline: 0 !important;
  }
  .navbar-burger:hover {
    background-color: #fff !important;
  }
  
  .navbar {
    background-color: transparent;
  }
  
  @media (max-width: 1023px) {
    .navbar-menu {
      text-align: center;
      padding-top: 1rem;
      padding-bottom: 1.3rem;
    }
    .navbar-menu .navbar-start > .navbar-item {
      padding: 0 !important;
    }
  }
  
  #navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5658;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  #navbar .navbar-link {
    padding-left: 26px;
    font-weight: 500;
    color: #111;
    font-family: "Rubik", sans-serif;
    text-transform: capitalize;
    font-size: 18px;
    padding: 8px !important;
    transition: all 0.25s ease;
  }
  #navbar .navbar-link:hover {
    color: #FF715B !important;
    background-color: transparent !important;
  }
  
  .navbar-item.has-dropdown:hover .navbar-link {
    background-color: transparent !important;
    color: #FF715B !important;
  }
  
  .navbar-brand {
    font-weight: 600;
  }
  
  .navbar-dropdown {
    visibility: hidden;
    filter: alpha(opacity=0);
    opacity: 0;
    transition: all 0.2s ease-in, visibility 0s linear 0.2s, transform 0.2s linear;
    -webkit-transition: all 0.2s ease-in, visibility 0s linear 0.2s, -webkit-transform 0.2s linear;
    -o-transition: all 0.2s ease-in, visibility 0s linear 0.2s, -o-transform 0.2s linear;
    -ms-transition: all 0.2s ease-in, visibility 0s linear 0.2s, -ms-transform 0.2s linear;
    width: 250px;
    padding: 8px 0;
    border-radius: 0px;
    display: block;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  @media (max-width: 1023px) {
    .navbar-dropdown {
      visibility: visible;
      filter: alpha(opacity=1);
      opacity: 1;
      display: none;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  .navbar-link::after {
    display: none !important;
  }
  
  .has-dropdown:hover .navbar-dropdown {
    visibility: visible;
    transition: all 0.45s ease 0s;
    opacity: 1;
  }
  
  .navbar-dropdown .navbar-item {
    padding: 0.7rem 1.5rem;
    text-transform: uppercase;
    font-size: 14px;
  }
  .navbar-dropdown .navbar-item:hover {
    background: #FF715B !important;
    color: #fff !important;
  }
  
  ul.dropdown-menu li {
    padding-left: 0px !important;
  }
  
  #navbar.nav-text-white .navbar-link,
  #navbar.nav-text-white .navbar-brand {
    color: #fff;
  }
  @media (max-width: 1023px) {
    #navbar.nav-text-white .navbar-link,
  #navbar.nav-text-white .navbar-brand {
      color: black;
    }
  }
  
  .navbar-item.has-dropdown {
    padding: 0.5rem 0.75rem;
  }
  
  .bg-1 {
    background: url("https://demo.themefisher.com/orbitor-bulma/images/bg/main-banner1.jpg") no-repeat 50% 50%;
    background-size: cover;
  }
  
  .bg-2 {
    background: url("https://demo.themefisher.com/orbitor-bulma/images/bg/bg-2.jpg");
    background-size: cover;
  }
  
  .banner {
    position: relative;
    overflow: hidden;
    background: #fff;
    background: url("https://demo.themefisher.com/orbitor-bulma/images/bg/main-banner1.jpg") no-repeat 80% 50%;
    background-size: cover;
    padding: 120px 0px;
  }
  @media (max-width: 575px) {
    .banner {
      padding: 80px 0px;
    }
  }
  .banner .block {
    padding-top: 120px;
  }
  @media (max-width: 575px) {
    .banner .block {
      padding-top: 100px;
    }
  }
  .banner .block h1 {
    font-size: 75px;
    line-height: 1.2;
    font-weight: 400;
    letter-spacing: -1.2px;
    text-transform: capitalize;
  }
  
  .letter-spacing {
    letter-spacing: 2px;
  }
  
  .text-color {
    color: #FF715B;
  }
  
  @media (max-width: 1215px) {
    .banner {
      background: #fff !important;
    }
  }
  @media (max-width: 1023px) {
    .banner {
      background: #fff !important;
    }
  }
  @media (max-width: 768px) {
    .banner .block h1 {
      font-size: 56px;
      line-height: 70px;
    }
    .banner {
      background: #fff !important;
    }
  }
  @media (max-width: 575px) {
    .banner .block h1 {
      font-size: 38px;
      line-height: 50px;
    }
    .banner {
      background: #fff !important;
    }
  }
  @media (max-width: 400px) {
    .banner .block h1 {
      font-size: 28px;
      line-height: 40px;
    }
    .banner {
      background: #fff !important;
    }
  }
  .about-item i {
    font-size: 50px;
  }
  .about-item a {
    color: #FF715B;
    font-weight: 500;
  }
  .about-item .icon {
    float: left;
    height: unset;
    width: unset;
  }
  .about-item .content {
    padding-left: 80px;
  }
  
  .process {
    position: relative;
  }
  .process:before {
    position: absolute;
    content: "";
    right: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: url("https://demo.themefisher.com/orbitor-bulma/images/bg/bg-half.png") no-repeat;
    background-position: top right;
  }
  
  .icon-block {
    padding: 50px 40px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 40px 0px rgba(85, 128, 255, 0.2);
  }
  .icon-block i {
    font-size: 35px;
    background: rgba(239, 50, 58, 0.08);
    width: 70px;
    height: 70px;
    text-align: center;
    padding-top: 16px;
    border-radius: 10px;
    display: inline-block;
    color: #FF715B;
  }
  .icon-block h5 {
    margin-top: 25px;
    margin-bottom: 15px;
  }
  .icon-block p {
    margin-bottom: 0px;
  }
  
  .feature-list ul li {
    margin-bottom: 15px;
    color: #111;
  }
  .feature-list ul li i {
    color: #FF715B;
  }
  
  .video-img {
    position: relative;
  }
  .video-img .video-play {
    position: absolute;
    content: "";
    left: 0px;
    right: 0px;
    top: 50%;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    margin-top: -50px;
    text-align: center;
  }
  .video-img .video-play i {
    font-size: 30px;
    width: 100px;
    height: 100px;
    background: #fff;
    color: #FF715B;
    border-radius: 100%;
    text-align: center;
    display: inline-block;
    line-height: 100px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.04);
  }
  
  .award-img-block {
    height: 160px;
    border: 1px solid #eee;
    align-items: center;
    display: flex;
    justify-content: center;
    background: #fff;
  }
  
  .video-play:hover {
    cursor: pointer;
  }
  
  .mt-30 {
    margin-top: 30px;
  }
  
  @media (max-width: 768px) {
    .custom-grid .mt-30 {
      margin-top: 0 !important;
    }
    .custom-grid .pb-0 {
      padding-bottom: 15px !important;
    }
    .custom-grid .pt-0 {
      padding-top: 15px !important;
    }
  }
  .feature {
    background: linear-gradient(to bottom, #05a5f9, #10dab7);
  }
  
  .feature-block {
    background: #fff;
    padding: 30px;
    text-align: center;
  }
  .feature-block i {
    font-size: 40px;
  }
  .feature-block h5 {
    margin-top: 20px;
  }
  
  .text-lg {
    font-size: 50px;
  }
  
  .counter {
    background: #FF715B;
  }
  .counter .counter-item h2 {
    color: #fff;
  }
  .counter .counter-item p {
    color: rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0px;
  }
  
  .counter--style i {
    font-size: 40px;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    color: #fafafa;
    background: #FF715B;
    display: inline-block;
    padding-top: 28px;
  }
  
  .team-block img {
    border-radius: 5px;
  }
  
  .service-item-list {
    padding: 15px 30px;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin: 0 0 20px;
  }
  .service-item-list i {
    font-size: 30px;
    line-height: 1.1;
    margin-right: 20px;
    float: left;
    color: #FF715B;
  }
  .service-item-list p {
    padding-left: 50px;
    line-height: 1.6;
    margin-bottom: 0px;
    color: #111;
  }
  
  .mb-50 {
    margin-bottom: 50px;
  }
  
  .content-padding {
    padding: 100px 0px;
  }
  
  .service-img {
    padding-right: 20px;
  }
  .service-img img {
    border-radius: 5px;
    box-shadow: 0px 0px 40px 0px rgba(85, 128, 255, 0.2);
  }
  
  .heading {
    margin-bottom: 90px;
  }
  
  .case-img,
  .case-content {
    padding: 30px;
  }
  @media (max-width: 768px) {
    .case-img,
  .case-content {
      padding: 0;
    }
  }
  
  .case-timeline {
    position: relative;
  }
  @media (max-width: 768px) {
    .case-timeline {
      margin-bottom: 60px;
    }
  }
  
  .case-timeline-divider {
    display: block;
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 50%;
    z-index: 0;
    width: 2px;
    height: auto;
    margin-left: -1px;
    background-color: #eee;
  }
  
  .case-timeline-dot {
    display: block;
    position: absolute;
    top: calc(50% - 10px);
    left: 50%;
    z-index: 1;
    content: " ";
    width: 20px;
    height: 20px;
    margin-left: -10px;
    background-color: #fff;
    border-width: 5px;
    border-style: solid;
    border-radius: 50%;
  }
  
  @media (max-width: 575px) {
    .case-timeline-dot {
      display: none;
    }
    .case-timeline-divider {
      display: none;
    }
  }
  @media (max-width: 400px) {
    .case-timeline-dot {
      display: none;
    }
    .case-timeline-divider {
      display: none;
    }
  }
  @media (max-width: 768px) {
    .case-timeline-dot {
      display: none;
    }
    .case-timeline-divider {
      display: none;
    }
  }
  @media (max-width: 1023px) {
    .case-timeline-dot {
      display: none;
    }
    .case-timeline-divider {
      display: none;
    }
  }
  .cta {
    background: url("https://demo.themefisher.com/orbitor-bulma/images/bg/bg-2.jpg") no-repeat;
    background-size: cover;
    position: relative;
  }
  
  .cta-content {
    padding: 40px;
  }
  @media (max-width: 575px) {
    .cta-content {
      padding: 40px 25px;
    }
  }
  
  .mb-30 {
    margin-bottom: 30px;
  }
  
  .text-color-primary {
    color: #FF715B;
  }
  
  .testimonial-wrap {
    margin-left: calc((100% - 1140px) / 2);
    margin-right: -10%;
    margin-top: -30px;
  }
  
  .testimonial-block {
    margin: 0px 10px;
    padding: 30px 0px;
    margin-left: 12px;
    padding-left: 15px;
    padding-right: 0px;
  }
  .testimonial-block p {
    background: #fff;
    box-shadow: 0px 0px 25px 0px rgba(85, 128, 255, 0.2);
    padding: 60px 40px;
    position: relative;
    margin-bottom: 30px;
    font-size: 18px;
  }
  .testimonial-block p:after {
    position: absolute;
    content: "";
    left: 20px;
    bottom: -20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 30px 0 0;
    border-color: #fff transparent transparent;
  }
  .testimonial-block .client-info .client-img {
    float: left;
    margin-right: 30px;
    margin-bottom: 20px;
  }
  .testimonial-block .client-info .client-img img {
    border-radius: 100%;
  }
  .testimonial-block .client-info .info h6 {
    margin-bottom: 0px;
  }
  
  @media (max-width: 575px) {
    .testimonial-wrap {
      margin-left: 0px;
    }
  }
  @media (max-width: 400px) {
    .testimonial-wrap {
      margin-left: 0px;
    }
  }
  @media (max-width: 768px) {
    .testimonial-wrap {
      margin-left: 0px;
    }
  }
  @media (max-width: 1023px) {
    .testimonial-wrap {
      margin-left: 0px;
    }
  }
  .pricing-block {
    padding: 20px 0px 50px 0px;
    border-top: 7px solid #FF715B;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border: 0px;
  }
  .pricing-block .price-header {
    padding: 30px 0px;
  }
  .pricing-block .price-header h2 {
    font-weight: 700;
    margin: 15px 0px;
  }
  .pricing-block .price-body {
    padding: 30px 0px;
    border-top: 2px solid rgba(0, 0, 0, 0.06);
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
    margin-bottom: 30px;
  }
  .pricing-block .price-body ul li {
    padding: 10px 0px;
  }
  .pricing-block .price-footer {
    margin-bottom: 30px;
  }
  
  .text-underline {
    text-decoration: underline;
  }
  
  .portfolio {
    position: relative;
  }
  .portfolio:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    height: 500px;
    background-color: #f7f7f7;
  }
  
  .portflio-item:before {
    content: " ";
    display: block;
    left: 0;
    height: 100%;
    width: 0%;
    position: absolute;
    transition: all 0.25s cubic-bezier(0.86, 0, 0.07, 1);
    background: rgba(255, 113, 91, 0.98);
    opacity: 0;
    overflow: hidden;
  }
  .portflio-item .portfolio-item-content {
    position: absolute;
    content: "";
    left: 30px;
    bottom: 0px;
    opacity: 0;
    transition: all 0.35s ease;
  }
  .portflio-item:hover:before {
    opacity: 1;
    width: 100%;
  }
  .portflio-item:hover .portfolio-item-content {
    opacity: 1;
    bottom: 20px;
  }
  .portflio-item .overlay-item {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: #fff;
    opacity: 0;
    transition: all 0.35s ease;
  }
  .portflio-item .overlay-item i {
    width: 80px;
    height: 80px;
    text-align: center;
    border-radius: 100%;
    display: inline-block;
    background: rgba(255, 255, 255, 0.06);
    line-height: 80px;
  }
  .portflio-item:hover .overlay-item {
    opacity: 1;
  }
  
  .portfolio-gallery {
    padding: 0px 30px;
  }
  
  .project-info-list {
    margin-top: 25px;
  }
  .project-info-list li {
    margin-bottom: 13px;
  }
  .project-info-list li strong {
    font-weight: 500;
    color: #111;
  }
  
  .contact-form-wrap .form-group {
    padding-bottom: 15px;
    margin: 0px;
  }
  .contact-form-wrap .form-group .form-control {
    height: 48px;
    border: 1px solid #EEF2F6;
    box-shadow: none;
    width: 100%;
  }
  .contact-form-wrap .form-group-2 {
    margin-bottom: 13px;
  }
  .contact-form-wrap .form-group-2 textarea {
    height: 135px;
    border: 1px solid #EEF2F6;
    box-shadow: none;
    width: 100%;
  }
  
  .address-block li {
    margin-bottom: 15px;
  }
  .address-block li i {
    font-size: 20px;
    width: 20px;
  }
  
  .short-info li {
    margin-bottom: 25px;
  }
  
  .social-icons li {
    margin: 0 6px;
  }
  .social-icons a {
    margin-right: 10px;
    font-size: 18px;
  }
  
  .google-map {
    position: relative;
  }
  
  .google-map #map {
    width: 100%;
    height: 450px;
  }
  
  .short-info {
    padding: 40px;
    border: 1px solid #eee;
  }
  
  .mt-90 {
    margin-top: 90px;
  }
  
  .blog-item {
    display: flex;
    margin-bottom: 30px;
  }
  @media (max-width: 768px) {
    .blog-item {
      display: block;
    }
  }
  
  .blog-thumb {
    width: 70%;
    margin-right: 40px;
  }
  
  .blog-item-content h3 {
    font-weight: 500;
  }
  
  .nav-links .page-numbers {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #eee;
    text-align: center;
    padding-top: 13px;
    font-weight: 600;
    margin-right: 10px;
  }
  .nav-links .page-numbers:hover {
    background: #FF715B;
    color: #fff;
  }
  
  .comment-area .comment-thumb {
    margin-right: 20px;
    margin-bottom: 10px;
  }
  .comment-area .comment-thumb img {
    width: 70px;
    border-radius: 5px;
    max-width: unset;
  }
  .comment-area h5 {
    font-size: 18px;
    font-weight: 500;
  }
  .comment-area span {
    font-size: 14px;
  }
  
  .posts-nav h6 {
    font-weight: 500;
  }
  
  .quote {
    font-size: 22px;
    color: #111;
    padding: 40px;
    font-style: italic;
    background: #f5f6ff;
    border-left: 5px solid #FF715B;
    margin: 25px 0px;
  }
  
  .comment-form .input {
    background: #f7f8fb;
    border-radius: 5px;
    border-color: #f7f8fb;
    height: 50px;
  }
  .comment-form textarea.input {
    height: auto;
  }
  
  @media (max-width: 575px) {
    .blog-thumb {
      width: 100%;
      float: none;
      margin-right: 0px;
      margin-bottom: 15px;
    }
    .blog-thumb img {
      width: 100%;
    }
  }
  @media (max-width: 400px) {
    .blog-thumb {
      width: 100%;
      float: none;
      margin-right: 0px;
      margin-bottom: 15px;
    }
    .blog-thumb img {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    .blog-thumb {
      width: 100%;
      float: none;
      margin-right: 0px;
      margin-bottom: 15px;
    }
    .blog-thumb img {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    .tag-option {
      display: block;
    }
  }
  
  .posts-nav .border {
    border: 1px solid #DEE2E6;
    height: 80px;
  }
  @media (max-width: 768px) {
    .posts-nav .border {
      height: 2px;
      width: 100%;
      margin: 30px 0;
    }
  }
  @media (max-width: 768px) {
    .posts-nav {
      display: block !important;
    }
  }
  
  @media (max-width: 768px) {
    .tag-option {
      display: block !important;
    }
    .tag-option .list-inline:first-child {
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 575px) {
    .comment-content,
  .comment-area-box {
      display: block !important;
    }
  }
  
  /*=================================================================
    Single Blog Page
  ==================================================================*/
  .post.post-single {
    border: none;
  }
  .post.post-single .post-thumb {
    margin-top: 30px;
  }
  
  .post-sub-heading {
    border-bottom: 1px solid #dedede;
    padding-bottom: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .post-social-share {
    margin-bottom: 50px;
  }
  
  .post-comments {
    margin: 30px 0;
  }
  .post-comments .media {
    margin-top: 20px;
  }
  .post-comments .media > .pull-left {
    padding-right: 20px;
  }
  .post-comments .comment-author {
    margin-top: 0;
    margin-bottom: 0px;
    font-weight: 500;
  }
  .post-comments .comment-author a {
    color: #FF715B;
    font-size: 14px;
    text-transform: uppercase;
  }
  .post-comments time {
    margin: 0 0 5px;
    display: inline-block;
    color: #808080;
    font-size: 12px;
  }
  .post-comments .comment-button {
    color: #FF715B;
    display: inline-block;
    margin-left: 5px;
    font-size: 12px;
  }
  .post-comments .comment-button i {
    margin-right: 5px;
    display: inline-block;
  }
  .post-comments .comment-button:hover {
    color: #FF715B;
  }
  
  .post-excerpt {
    margin-bottom: 60px;
  }
  .post-excerpt h3 a {
    color: #000;
  }
  .post-excerpt p {
    margin: 0 0 30px;
  }
  .post-excerpt blockquote.quote-post {
    margin: 20px 0;
  }
  .post-excerpt blockquote.quote-post p {
    line-height: 30px;
    font-size: 20px;
    color: #FF715B;
  }
  
  .single-blog {
    background-color: #fff;
    margin-bottom: 50px;
    padding: 20px;
  }
  
  .blog-subtitle {
    font-size: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dedede;
    margin-bottom: 25px;
    text-transform: uppercase;
  }
  
  .next-prev {
    border-bottom: 1px solid #dedede;
    border-top: 1px solid #dedede;
    margin: 20px 0;
    padding: 25px 0;
  }
  .next-prev a {
    color: #000;
  }
  .next-prev a:hover {
    color: #FF715B;
  }
  .next-prev .prev-post i {
    margin-right: 10px;
  }
  .next-prev .next-post i {
    margin-left: 10px;
  }
  
  .social-profile ul li {
    margin: 0 10px 0 0;
    display: inline-block;
  }
  .social-profile ul li a {
    color: #4e595f;
    display: block;
    font-size: 16px;
  }
  .social-profile ul li a i:hover {
    color: #FF715B;
  }
  
  .comments-section {
    margin-top: 35px;
  }
  
  .author-about {
    margin-top: 40px;
  }
  
  .post-author {
    margin-right: 20px;
  }
  
  .post-author > img {
    border: 1px solid #dedede;
    max-width: 120px;
    padding: 5px;
    width: 100%;
  }
  
  .comment-list ul {
    margin-top: 20px;
  }
  .comment-list ul li {
    margin-bottom: 20px;
  }
  
  .comment-wrap {
    border: 1px solid #dedede;
    border-radius: 1px;
    margin-left: 20px;
    padding: 10px;
    position: relative;
  }
  .comment-wrap .author-avatar {
    margin-right: 10px;
  }
  .comment-wrap .media .media-heading {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .comment-wrap .media .media-heading a {
    color: #FF715B;
    font-size: 13px;
  }
  .comment-wrap .media .comment-meta {
    font-size: 12px;
    color: #888;
  }
  .comment-wrap .media p {
    margin-top: 15px;
  }
  
  .comment-reply-form {
    margin-top: 80px;
  }
  .comment-reply-form input, .comment-reply-form textarea {
    height: 35px;
    border-radius: 0;
    box-shadow: none;
  }
  .comment-reply-form input:focus, .comment-reply-form textarea:focus {
    box-shadow: none;
    border: 1px solid #FF715B;
  }
  .comment-reply-form textarea, .comment-reply-form .btn-main {
    height: auto;
  }
  
  .sidebar-wrap {
    padding-left: 40px;
  }
  @media (max-width: 1023px) {
    .sidebar-wrap {
      padding-left: 0;
    }
  }
  
  .sidebar-widget {
    margin-bottom: 30px;
    padding-bottom: 35px;
  }
  .sidebar-widget h5 {
    margin-bottom: 25px;
  }
  .sidebar-widget.latest-post .media img {
    border-radius: 7px;
  }
  .sidebar-widget.latest-post .media h6 {
    font-weight: 500;
    line-height: 1.4;
  }
  .sidebar-widget.latest-post .media p {
    font-size: 12px;
  }
  .sidebar-widget.category ul li {
    margin-bottom: 10px;
  }
  .sidebar-widget.category ul li a {
    color: #111;
    transition: all 0.3s ease;
  }
  .sidebar-widget.category ul li a:hover {
    color: #FF715B;
    padding-left: 5px;
  }
  .sidebar-widget.category ul li span {
    margin-left: 10px;
  }
  .sidebar-widget.tags a {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.075em;
    line-height: 41px;
    height: 41px;
    font-weight: 500;
    border-radius: 20px;
    color: #666;
    display: inline-block;
    background-color: #eff0f3;
    margin: 0 7px 10px 0;
    padding: 0 25px;
    transition: all 0.2s ease;
  }
  .sidebar-widget.tags a:hover {
    color: #fff;
    background: #FF715B;
  }
  
  .search-form {
    position: relative;
  }
  .search-form i {
    position: absolute;
    right: 15px;
    top: 35%;
  }
  
  .footer {
    padding-bottom: 10px;
    background: #000;
  }
  .footer .copyright a {
    font-weight: 600;
  }
  
  .lh-35 {
    line-height: 35px;
  }
  
  .logo {
    font-weight: 600;
    letter-spacing: 1px;
  }
  .logo h3 {
    color: #fff;
  }
  .logo span {
    color: #FF715B;
  }
  
  .widget h4 {
    color: #fff;
    font-weight: 600;
  }
  .widget a {
    color: rgba(255, 255, 255, 0.7);
  }
  .widget a:hover {
    color: #FF715B;
  }
  .widget p {
    color: rgba(255, 255, 255, 0.7);
  }
  
  .footer-btm {
    border-top: 1px solid rgba(255, 255, 255, 0.06);
  }
  
  .footer-socials li a {
    margin-right: 7px;
  }
  
  .copyright {
    color: rgba(255, 255, 255, 0.7);
  }
  .copyright a {
    color: rgba(255, 255, 255, 0.8);
  }
  .copyright a:hover {
    color: #FF715B;
  }
  
  .widget-contact h6 {
    font-weight: 500;
    margin-bottom: 18px;
  }
  .widget-contact h6 i {
    color: #FF715B;
  }


  .heading {
    text-align: center;
    color: #454343;
    font-size: 30px;
    font-weight: 700;
    position: relative;
    margin-bottom: 70px;
    text-transform: uppercase;
    z-index: 999;
  }
  .white-heading{
    color: #ffffff;
  }
  .heading:after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    height: 40px;
    width: 180px;
    border-radius: 4px;
    transform: translateX(-50%);
    /* background: url(img/heading-line.png); */
    background-repeat: no-repeat;
    background-position: center;
  }
  .white-heading:after {
    background: url(https://i.ibb.co/d7tSD1R/heading-line-white.png);
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .heading span {
    font-size: 18px;
    display: block;
    font-weight: 500;
  }
  .white-heading span {
    color: #ffffff;
  }
  /*-----Testimonial-------*/
  
  .testimonial:after {
    position: absolute;
    top: -0 !important;
    left: 0;
    content: " ";
    /* background: url(img/testimonial.bg-top.png); */
    background-size: 100% 100px;
    width: 100%;
    height: 100px;
    float: left;
    z-index: 99;
  }
  
  .testimonial {
    min-height: 375px;
    position: relative;
    background: url(https://i.ibb.co/PTJDkgb/testimonials.jpg);
    padding-top: 50px;
    padding-bottom: 50px;
    background-position: center;
        background-size: cover;
  }
 
  .testimonial .carousel {
  padding-bottom:50px;
  }
  .testimonial .carousel-control-next-icon, .testimonial .carousel-control-prev-icon {
    width: 35px;
    height: 35px;
  }
  /* ------testimonial  close-------*/


  .h3-coustom{
    display: block;
    font-size: 1em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}


.scta-red {
  border-radius: 3px;
  border: solid 1px #C60929;
  padding: 5%;
  float: left;
}

.scta-blue {
  border: solid 1px #0542B9;
  padding: 5%;
  float: left;
  border-radius: 3px;
}
.scta-green {
  border: solid 1px #106B03;
  padding: 5%;
  float: left;
  border-radius: 3px;
}
.scta-yellow {
  border: solid 1px #F5C52D;
  padding: 5%;
  float: left;
  border-radius: 3px;
}
  


@media (min-width: 1024px)  {
.coustom-colum{
  width:'90%';
  margin:'auto'
}
.scta-body{
width:'90%';
margin:'auto'
}
.scta-sub{

}
.coustom-container{
  margin-top: 0%;
}
  .landing-h4-constom{
    font-size: 35px !important;
    margin-top: 5% !important;
  }
  .landing-h6-constom{
    font-size: 65px !important;
  }


.costom-image-divice img{
  width: 60%;
  border-radius: 10px;
}

.costom-image-divice1 img{
  width: 60%;
  border-radius: 10px;
}

.NavMobile-left > *:first-child{
position: relative;
right: 10%
}
.show-nomoblie{
  display: none;
}
.landing-create img{
  width: 30%;
  /* height: 100px; */
}
.landing-create h6{
  float: right;
  position: relative;
  right: 7%;
  top:10%
}
.landing-Cheduling img{
  width: 20%;
  /* height: 100px; */
}
.landing-Cheduling h6{
  float: right;
  position: relative;
  right: 7%;
  top:10%
}

.landing-createclass img{
  width: 19%;
  height: 70px;
}

.landing-createclass h6{
  float: right;
  position: relative;
  right: 7%;
  top:10%
}
.landing-create-control img{
  width: 15%;
  height: 56px;
}

.landing-create-control h6{
  float: right;
  position: relative;
  right: 7%;
  top:10%
}
.landing-create-Payment img{
  width: 20%;
  height: 60px;
}

.landing-create-Payment h6{
  float: right;
  position: relative;
  right: 7%;
  top:10%
}
.landing-create-security img{
  width: 15%;
  height: 60px;
}

.landing-create-security h6{
  float: right;
  position: relative;
  right: 7%;
  top:10%
}
.home-slider-page {
  width: 60%;
  margin: auto;
  /* //  background:blue; */
}
.home-slider {
  width: 90%;
  border-radius: 10px;
  padding-top: 25%;
  padding-bottom: 25%;
  /* // height: 250px; */
  margin: auto;
  margin-top: 5%;
  background-size: cover;
  background-repeat: no-repeat;
}
.slider--feature {
  width: 98%;
  margin: auto;
  position: relative; 
  padding-top: 10%;
}
.slider--feature h1 {
  text-align: center !important;
  font-family: "Exo";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 2%;
  /* // background-color:red; */
  /* or 123% */
  color: #190042;
}
.slider--feature h2 {
  font-family: "Exo";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #575757;
  margin-bottom: 3%;
  margin-top: 4%;
  text-align:center
}
.home-slide-arrow {
  /* // width: 50%; */
  margin: auto;
  text-align: left;
  padding-left: 4%;
}
.home-slide-arrow a {
  position: relative;
  right: 5%;
  margin-right: 5%;
}
.home-page-what h1 {
  font-family: "Exo";
  font-style: normal;
  font-weight: 300%;
  font-size: 50px;
  line-height: 56px;
  /* identical to box height, or 112% */
  text-align: center;
  text-transform: capitalize;
  color: #000000;
}
.home-page-what h3 {
  font-family: "Exo";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 1%;
  /* or 200% */
  text-align: center;
  color: #575757;
}

.home-page-ready {
  width: 68%;
  background: url("https://res.cloudinary.com/codepally/image/upload/v1618825750/backgroud_ls9t81.svg")
    no-repeat;
  height: auto;
  padding-top: 8%;
  padding-bottom: 8%;
  /* // background-size: cover; */
  background-size: 100% 95%;
  border-radius: 10px;
  margin: auto;
  margin-top: 5%;
  text-align: center;
}

.home-page-ready h1 {
  font-family: "Exo";
  font-style: normal;
  /* // font-weight: ; */
  font-size: 270%;
  /* // line-height: 66px; */
  text-align: center;
  color: #ffffff;
  /* // margin-top: 20%; */
}
.show-nomoblie{
  display: none;
}

}
/* // moblie view */

@media screen and (max-width: 1023px) {
  .navbar-custom{
    position: relative;
    right: 1%;
  }
  .coustom-colum{
    width:'100%';
    margin:'auto';
  
  }
  .show-nomoblie{
    width: 55%;
    display: block;
    /* background-color: red; */
    position: relative;
    right: 20%;
  }
  .show-nomoblie  > *:first-child{
    margin-right:7%;
  }
  .coustom-container{
    margin-top: 175% !important;
  }
 .scta-body{
   width:100%;
   margin: auto;
 }
 .scta-sub{
   margin-top: 10%;
 }

  .landing-h4-constom{
    font-size: 28px !important;
    margin-top: 10% !important;
  }
  .landing-h6-constom{
    font-size: 30px !important;
  }
  
  .costom-image-divice {
    position: relative;
    right: 2%;
  }
.costom-image-divice img{
  width: 100%;
  border-radius: 10px;

  /* margin-right: 4%; */
}

  .costom-image-divice{
    width: 55%;
    position: relative;
    /* left: 2%; */
  }
.costom-image-divice1 img{
  width: 60%;
  border-radius: 10px;

  /* margin-right: 4%; */
}
.NavMobile-left{
  display: none
}


.landing-create img{
  width: 25%;
  /* height: 100px; */
}
.landing-create h6{
  float: right;
  position: relative;
  right: 7%;
  top:10%
}
.landing-Cheduling img{
  width: 20%;
  /* height: 100px; */
}
.landing-Cheduling h6{
  float: right;
  position: relative;
  font-size: 13px;
  right: 1%;
  top:10%
}
.landing-createclass img{
  width: 19%;
  height: 70px;
}

.landing-createclass h6{
  float: right;
  position: relative;
  right: 7%;
  top:10%
}
.landing-create-control img{
  width: 15%;
  height: 56px;
}

.landing-create-control h6{
  float: right;
  position: relative;
  right: 7%;
  top:10%
}
.landing-create-Payment img{
  width: 20%;
  height: 60px;
}

.landing-create-Payment h6{
  float: right;
  position: relative;
  right: 7%;
  top:10%
}
.landing-create-security img{
  width: 15%;
  height: 60px;
}

.landing-create-security h6{
  float: right;
  position: relative;
  right: 7%;
  top:10%
}
.home-slider-page {
  width: 65%;
  height: auto;
  margin: auto;
  /* //  background:blue; */
}
.home-slider {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  padding-top: 25%;
  padding-bottom: 25%;
  /* // height: 250px; */
  margin: auto;
  margin-top: 5%;
  background-size: cover;
  background-repeat: no-repeat;
}
.slider--feature {
  width: 98%;
  margin: auto;
  position: relative; 
}
.slider--feature h1 {
  text-align: center !important;
  font-family: "Exo";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 2%;
  /* // background-color:red; */
  /* or 123% */
  color: #190042;
}
.slider--feature h2 {
  font-family: "Exo";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #575757;
  margin-bottom: 3%;
  margin-top: 4%;
  text-align:center
}
.home-slide-arrow {
  /* // width: 50%; */
  margin: auto;
  text-align: left;
  padding-left: 4%;
}
.home-slide-arrow a {
  position: relative;
  right: 5%;
  margin-right: 5%;
}
.home-page-what h1 {
  font-family: "Exo";
  font-style: normal;
  font-weight: 300%;
  font-size: 50px;
  line-height: 56px;
  /* identical to box height, or 112% */
  text-align: center;
  text-transform: capitalize;
  color: #000000;
}
.home-page-what h3 {
  font-family: "Exo";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 1%;
  /* or 200% */
  text-align: center;
  color: #575757;
}

.home-page-ready {
  width: 68%;
  background: url("https://res.cloudinary.com/codepally/image/upload/v1618825750/backgroud_ls9t81.svg")
    no-repeat;
  height: auto;
  padding-top: 8%;
  padding-bottom: 8%;
  /* // background-size: cover; */
  background-size: 100% 95%;
  border-radius: 10px;
  margin: auto;
  margin-top: 5%;
  text-align: center;
}

.home-page-ready h1 {
  font-family: "Exo";
  font-style: normal;
  /* // font-weight: ; */
  font-size: 270%;
  /* // line-height: 66px; */
  text-align: center;
  color: #ffffff;
  /* // margin-top: 20%; */
}

}



