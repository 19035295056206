.terms-body{
    padding-top: 5%;
}
.section-menu__title{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0.75rem;
}
.section-menu-primary>li {
    margin-top: 5%;
    margin-bottom: 5%;
    border-bottom: 0.0625rem solid #ccc;
}
.terms-side{
    padding-top: 5%;
}
.close{
    display: none;
}