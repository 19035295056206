
.sign-in-body{
    width: 37%;
    margin: auto;
    margin-top: 10%;
    text-align: center;
}
.card-login{
  width: 70%;
  margin: auto;
}
.login-image{
  width: 90%;
}

.login-image img{
    width: 30%;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
}
.login-azura{
 width: 110% !important;
 margin: auto;
 padding-top: 5%;
 padding-bottom: 5%;
 border-radius: 10px;
 position: relative;
 right: 5%;
 /* background-color: red; */
}
.login-azura p{
 text-align: left;
 margin-left: 10%;
}
.login-azura img{
  float: right;
  width: 12%;
  position: relative;
  right: 5%;
  top: 2px;

}
.my-card{
    
    background-color: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
    color: #4a4a4a;
    max-width: 110%;
    position: relative;

}