


@media (min-width: 1024px)  {
    .navbar-custom a img{
        width:7%;
        
    }
    .navbar-custom b{
        font-size: 30px;
        position: relative;
        left:1%;color: #46178F;
        top:4px
    }
}
/* // moblie view */

@media screen and (max-width: 1023px) {
    .navbar-custom {
        margin-left: 1.5%;
        /* width: 50% !important; */
        /* background-color:red; */
    }
    .navbar-custom a img{
        width:14%;
        margin-left: 2%;
        position: relative;
        bottom:9px
    }
    .navbar-custom b{
        color: #46178F;
        font-size: 30px;
        position: relative;
        bottom: 5px;
    }
    .nav-bottom-cs{
      position: relative;
      text-align: left;


      /* left: -10px !important */
    }
}