
.asideCostom {
    /* display: none; */
    position: fixed;
    width: 17%;
    top: 0;
    left: 0;
    z-index: 40;
    height: 100vh;
    padding: 0;
    box-shadow: none;
    background: #2e323a;
}
.menu-list-constom{
    margin-left: 8%;
    margin-bottom: 5%;
}
.menu-list-constom li{
    margin-bottom: 5%;
}
.asideCostom .aside-tools {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #17191e;
    color: #fff;
    line-height: 3.25rem;
    height: 3.25rem;
    padding-left: 0.75rem;
    flex: 1;
}
.asideCostom  .menu-label {
    padding: 0 0.75rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}
